<template>
    <div class="salary-issuance-container">
        <div class="salary-issuance-container">
            <el-card shadow="always" class="card-container">
              <!-- Title for Salary Issuance -->
              <h2 class="table-title">Salary Issuance for Employees</h2>
        
              <!-- Employee Table for Issuing Salary -->
              <div class="table-and-button">
                <el-table
                  v-loading="loading"
                  :data="employees"
                  @selection-change="handleSelectionChange"
                  border
                  stripe
                  size="small"
                  class="el-table"
                >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column prop="name" label="Employee Name" align="center"></el-table-column>
                  <el-table-column prop="hourly_rate" label="Hourly Rate" align="center"></el-table-column>
                  <el-table-column prop="mandatory_attendance_time" label="Mandatory Attendance (minutes)" align="center"></el-table-column>
                  <el-table-column label="Operations" align="center">
                    <template #default="scope">
                      <el-button
                        type="primary"
                        size="small"
                        @click="openSalaryDialog(scope.row)"
                      >
                        Issue Salary
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                
                <!-- Issue Salaries for Selected Button -->
                <div class="action-button">
                  <el-button
                    type="primary"
                    icon="el-icon-document-checked"
                    @click="issueSalariesForSelected"
                  >
                    Issue Salaries for Selected
                  </el-button>
                </div>
              </div>
        
              <!-- Title for Issued Salaries Records -->
              <h3 class="table-subtitle">Issued Salaries Records</h3>
        
              <!-- Issued Salaries Table -->
              <el-table
                v-loading="loading"
                :data="issuedSalaries.data"
                border
                stripe
                size="small"
                class="el-table"
              >
                <el-table-column prop="employee.name" label="Employee Name" align="center"></el-table-column>
                <el-table-column prop="issued_date" label="Issued Date" align="center"></el-table-column>
                <el-table-column prop="bonus" label="Bonus" align="center"></el-table-column>
                <el-table-column prop="custom_deduction" label="Custom Deduction" align="center"></el-table-column>
                <el-table-column prop="net_salary" label="Net Salary" align="center"></el-table-column>
                <el-table-column label="Actions" align="center" width="180">
                  <template #default="scope">
                    <el-button
                      type="primary"
                      size="small"
                      @click="printSalary(scope.row)"
                    >
                      Print Salary
                    </el-button>
                    <el-button style="margin-right:2px;" type="danger" size="small" @click="deleteIssuedSalary(scope.row.id)">Delete</el-button>
                  </template>
                </el-table-column>
              </el-table>
        
              <!-- Pagination -->
              <el-pagination
                background
                layout="prev, pager, next"
                :total="issuedSalaries.total"
                :page-size="perPage"
                :current-page="currentPage"
                @current-change="fetchIssuedSalaries"
                class="pagination"
              />
            </el-card>
          </div>
        <!-- Salary Calculation Dialog -->
      <!-- Salary Calculation Dialog -->
      <el-dialog title="Calculate Salary" v-model="isDialogVisible" width="30%">
        <div>
            <h4>Calculate Salary for {{ selectedEmployee?.name }}</h4>
            <el-form :model="form">
                <el-form-item label="Bonus">
                    <el-input v-model.number="form.bonus" type="number" min="0" placeholder="Enter bonus"></el-input>
                </el-form-item>
                <el-form-item label="Custom Deduction">
                    <el-input v-model.number="form.customDeduction" type="number" min="0" placeholder="Enter deduction"></el-input>
                </el-form-item>
                <el-form-item v-if="totalDelayMinutes > 0 && isDialogVisible" label="Select Deduction">
                    <el-select v-model="form.selectedDeduction" placeholder="Select deduction" @change="selectDeduction">
                        <el-option
                            v-for="deduction in deductions"
                            :key="deduction.id"
                            :label="deduction.amount"
                            :value="deduction.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="calculateSalary">Calculate Salary</el-button>
            <div v-if="netSalary !== null" style="margin-top: 15px;">
                <h4>Net Salary: {{ netSalary }} {{ selectedEmployee?.currency }}</h4>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isDialogVisible = false">Close</el-button>
        </span>
    </el-dialog>
    
    
    </div>
</template>

<script>
  import logo from "@/assets/img/logo-school-one.png";
  import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
  import signatureHareth from "@/assets/img/signature_hareth.png";
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {
    data() {
        return {
            employees: [],
            selectedEmployees: [],
            issuedSalaries: {
                data: [],
                total: 0, // Total number of records for pagination
            },
            selectedEmployee: null,
            isDialogVisible: false,
            loading: false,
            form: {
                bonus: 0,
                customDeduction: 0,
                selectedDeduction: null, // For selected deduction
            },
            deductions: [], // Store available deductions
            netSalary: null,
            perPage: 10, // Records per page
            currentPage: 1, // Current page number
            totalDelayMinutes: 0, // Store total delay minutes
        };
    },
    async created() {
        await this.fetchEmployees();
        await this.fetchIssuedSalaries(this.currentPage); // Fetch initial data with the current page
        await this.fetchDeductions(); // Fetch deductions on creation
    },
    methods: {
        printSalary(salary) {
  let deductionsContent = '';
  let totalDeductions = 0;

  if (salary.deduction_id) {
    deductionsContent += `
      <tr>
        <td>Reason for Deduction (سبب الخصم)</td>
        <td>${salary.deduction.amount}</td>
      </tr>
    `;
    totalDeductions += parseFloat(salary.deduction.amount);
  }

  // Check if custom_deduction is present and has a non-zero value
  if (salary.custom_deduction && parseFloat(salary.custom_deduction) > 0) {
    deductionsContent += `
      <tr>
        <td>Custom Deduction (الخصم المخصص)</td>
        <td>${salary.custom_deduction}</td>
      </tr>
    `;
    totalDeductions += parseFloat(salary.custom_deduction);
  }

  const printContent = `
    <main class="main-wrapper position-relative">
      <div class="modern-invoice3" id="download-section">
        <div class="invoice-top">
          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start mb-3 mb-sm-1">
              <img style="width:50%;height:50%;" src="${require('@/assets/img/logo-school.png')}" alt="invoice">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-end mb-3 mb-sm-1">
              <h6 class="text-25 text-uppercase mb-0 mt-0">Salary Receipt (إيصال الراتب)</h6>
            </div>
          </div>
          <hr>
        </div>

        <div class="invoice-details pt-20">
          <div class="row">
            <div class="col-sm-12 order-sm-0">
              <strong class="text-18 mb-3 d-inline-block">Date (التاريخ):</strong>
              <address class="mb-4">${salary.issued_date}</address>
            </div>
            <div class="col-sm-6 text-left">
              <strong class="text-18 mb-3 d-inline-block">To Mr./Ms. ${salary.employee.name} (إلى السيد/السيدة):</strong>
            </div>
          </div>
          <div class="col-sm-12 text-center">
            <p class="mb-4">
              نود أن نشكرك على جهودك والتزامك الرائع في العمل. نتمنى لك المزيد من التقدم والنجاح.
            </p>
            <p class="mb-4">
              We would like to thank you for your dedication and hard work. Wishing you continued success.
            </p>
          </div>
        </div>

        <div class="table-responsive invoice-table mb-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Employee Name (اسم الموظف)</th>
                <th>Base Salary (الراتب الأساسي)</th>
                <th>Currency (العملة)</th>
                <th>Issued Date (تاريخ الإصدار)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${salary.employee.name}</td>
                <td>${salary.base_salary}</td>
                <td>${salary.currency}</td>
                <td>${salary.issued_date}</td>
              </tr>
            </tbody>
          </table>
        </div>

        ${deductionsContent ? `
        <div class="table-responsive invoice-table mb-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Deduction Reason (سبب الخصم)</th>
                <th>Amount (المبلغ)</th>
              </tr>
            </thead>
            <tbody>
              ${deductionsContent}
            </tbody>
          </table>
        </div>
        ` : ''}

        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-6"></div>
          <div class="col-lg-4 col-md-4 col-sm-6 ms-auto">
            <table class="table">
              <tbody>
                <tr>
                  <td><strong>Bonus (العلاوة)</strong></td>
                  <td>${salary.bonus}</td>
                </tr>
                ${totalDeductions > 0 ? `
                <tr>
                  <td><strong>Total Deductions (إجمالي الخصومات)</strong></td>
                  <td>${totalDeductions}</td>
                </tr>` : ''}
                <tr class="total-pay">
                  <td><strong>Net Salary (صافي الراتب)</strong></td>
                  <td><strong>${salary.net_salary}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center mb-4">
            <qrcode-vue :value="${salary.id}" :size="128" :level="'H'"></qrcode-vue>
          </div>
          <div class="col-sm-4 text-center">
            <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Director of Financial Affairs (مدير الشؤون المالية)</p><hr>
          </div>
          <div class="col-sm-4 text-center">
            <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Director of Human Resources (مدير الموارد البشرية)</p><hr>
          </div>
          <div class="col-sm-4 text-center">
            <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Chairman of the Board (رئيس مجلس الإدارة)</p><hr>
          </div>
        </div>
      </div>
    </main>
  `;

  const newWindow = window.open('', '', 'width=800, height=600');
  newWindow.document.write(`
    <html>
      <head>
        <title>Salary Receipt (إيصال الراتب)</title>
        <link rel="stylesheet" href="/main-style.css">
      </head>
      <body>${printContent}</body>
      <style>
        @font-face {
          font-family: 'TajawalBold';
          src: url('${TajawalBold}') format('truetype');
        }
        p, strong {
          font-family: 'TajawalBold';
        }
      </style>
    </html>
  `);

  newWindow.document.close();

  setTimeout(() => {
    newWindow.print();
    newWindow.close();
  }, 500);
},
async deleteIssuedSalary(id) {
      try {
        await api.delete(`/issued-salaries-per-hour/${id}`);
        this.$message.success("Issued salary deleted successfully");
        // Refresh the table data after deletion
        this.fetchIssuedSalaries(this.currentPage);
      } catch (error) {
        this.$message.error("Failed to delete issued salary");
        console.error("Delete error:", error);
      }
    },

        handleSelectionChange(selectedEmployees) {
        this.selectedEmployees = selectedEmployees.map(emp => emp.id);
    },
    async issueSalariesForSelected() {
        if (this.selectedEmployees.length === 0) {
            this.$message.error("Please select at least one employee");
            return;
        }

        try {
            const response = await api.post('employees/issue-salaries', {
                employee_ids: this.selectedEmployees,
                deduction_id: this.form.selectedDeduction,
                bonus: this.form.bonus,
                custom_deduction: this.form.customDeduction
            });
            this.$message.success("Salaries issued successfully");
            this.fetchIssuedSalaries();
        } catch (error) {
            console.error("Failed to issue salaries", error);
            this.$message.error("Failed to issue salaries");
        }
    },
        async fetchEmployees() {
            this.loading = true
            try {
                const response = await api.get('employees/with-salary-records');
                this.employees = response.data;
            } catch (error) {
                console.error("Failed to fetch employees", error);
            }finally{
                this.loading = false;
            }
        },
        async fetchIssuedSalaries(page = 1) { // Add page parameter
            this.loading = true;
            try {
                const response = await api.get('/issued-salaries-per-hour', {
                    params: { per_page: this.perPage, page: page }, // Include pagination parameters
                });
                this.issuedSalaries = response.data; // Update the issuedSalaries object with response
                this.currentPage = page; // Update the current page
            } catch (error) {
                console.error("Failed to fetch issued salaries", error);
            }finally{
                this.loading = false ;
            }
        },
        openSalaryDialog(employee) {
            this.selectedEmployee = employee;
            this.isDialogVisible = true;
            this.form.bonus = 0;
            this.form.customDeduction = 0;
            this.netSalary = null;
        },
        async fetchDeductions() {
            try {
                const response = await api.get('deductions-per-hour'); // Adjust the endpoint accordingly
                this.deductions = response.data;
            } catch (error) {
                console.error("Failed to fetch deductions", error);
            }
        },
        async calculateSalary() {
    if (!this.selectedEmployee) return;

    this.loading = true;
    try {
        // Fetch delay information
        const delayResponse = await api.get(`salary/delay-check/${this.selectedEmployee.id}`);
        this.totalDelayMinutes = delayResponse.data.total_delay_minutes;

        if (this.totalDelayMinutes > 0) {
            this.showDelayDialog();
        } else {
            // Directly perform the salary calculation if there's no delay
            await this.performSalaryCalculation(false); // No deduction since there's no delay
        }
    } catch (error) {
        console.error("Salary calculation failed", error);
    } finally {
        this.loading = false;
    }
},

showDelayDialog() {
    const delayMessage = this.formatDelay(this.totalDelayMinutes);
    this.$confirm(`This Employee Has ${delayMessage} of delay. Would you like to deduct an amount from this employee?`, 'Delay Alert', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
    }).then(() => {
        // Show the dialog for salary calculation where the user can select the deduction
        this.isDialogVisible = true; // Show the dialog
    }).catch(() => {
        // If the user selects 'No', calculate salary without any deduction
        this.performSalaryCalculation(false); // Pass false to indicate no deduction
    });
},

async performSalaryCalculation(withDeduction = true) {
    try {
        // Make sure deduction is selected if there's a delay
        if (withDeduction && !this.form.selectedDeduction) {
            this.$message.error('Please select a deduction amount before calculating the salary.');
            return; // Prevent calculation if no deduction is selected
        }

        const response = await api.post(`salary/calculate/${this.selectedEmployee.id}`, {
            bonus: this.form.bonus,
            custom_deduction: this.form.customDeduction,
            deduction_id: withDeduction ? this.form.selectedDeduction : null // Send selected deduction or null
        });
        this.netSalary = response.data.net_salary;
        await this.fetchIssuedSalaries(this.currentPage); // Refresh issued salaries list
        this.isDialogVisible = false; // Close the dialog after calculation
    } catch (error) {
        console.error("Salary calculation failed", error);
    }
},

selectDeduction() {
    if (this.form.selectedDeduction) {
        // Automatically perform the salary calculation after selecting a deduction
        this.performSalaryCalculation(true); // true indicates that deduction should be considered
    }
},
formatDelay(totalDelayMinutes) {
    const hours = Math.floor(totalDelayMinutes / 60);
    const mins = totalDelayMinutes % 60; // Use the raw value for minutes
    return `${hours} hour(s) and ${mins} minute(s)`;
}

    },
};
</script>

<style>
.salary-issuance-container {
    padding: 20px;
}
h2, h3 {
    text-align: center;
    color: #333;
    margin-bottom: 10px;
}
</style>
