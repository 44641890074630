<template>
  <div class="container">
    <el-card>
      <h3 class="text-center">الوجبات الممنوعة عن الطلبة</h3>
      <div class="row mb-3">
        <!-- اختيار الطالب -->
        <div class="col-md-6">
          <el-select filterable v-model="selectedStudent" placeholder="اختر طالب" @change="fetchRestrictedMeals"
            class="w-100">
            <el-option v-for="student in students" :key="student.id" :label="student.name" :value="student.id" />
          </el-select>
        </div>
        <!-- اختيار الوجبة -->
        <div class="col-md-6">
          <el-select filterable v-model="selectedMeal" placeholder="اختر وجبة" class="w-100">
            <el-option v-for="meal in meals" :key="meal.id" :label="meal.name" :value="meal.id" />
          </el-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 text-center">
          <el-button type="primary" @click="addMealRestriction" :disabled="!selectedStudent || !selectedMeal">إضافة
            تقييد</el-button>
        </div>
      </div>

      <!-- عرض الوجبات المقيدة -->
      <el-table :data="restrictedMeals" style="width: 100%">
        <el-table-column prop="meal.name" label="الوجبة"></el-table-column>
        <el-table-column label="الإجراءات">
          <template #default="{ row }">
            <el-button type="danger" @click="removeMealRestriction(row.meal.id)" size="mini">إزالة</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ElCard, ElSelect, ElOption, ElButton, ElTable, ElTableColumn, ElMessage } from 'element-plus';

// إعداد axios مع توكن المصادقة
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElSelect,
    ElOption,
    ElButton,
    ElTable,
    ElTableColumn,
  },
  data() {
    return {
      students: [],
      meals: [],
      restrictedMeals: [],
      selectedStudent: null,
      selectedMeal: null,
    };
  },
  methods: {
    async fetchStudents() {
      try {
        const response = await api.get('/students-customers');
        this.students = response.data;
      } catch (error) {
        ElMessage.error('فشل في تحميل قائمة الطلاب');
      }
    },
    async fetchMeals() {
      try {
        const response = await api.get('/meals');
        this.meals = response.data;
      } catch (error) {
        ElMessage.error('فشل في تحميل قائمة الوجبات');
      }
    },
    async fetchRestrictedMeals() {
      if (!this.selectedStudent) return;
      try {
        const response = await api.get(`/students-restricted-meals/${this.selectedStudent}/restricted-meals`);
        this.restrictedMeals = response.data;
      } catch (error) {
        ElMessage.error('فشل في تحميل الوجبات المقيدة للطالب');
      }
    },
    async addMealRestriction() {

      try {
        await api.post(`/students-restricted-meals/restricted-meals`, {
          meal_id: this.selectedMeal,
          student_id: this.selectedStudent
        });
        ElMessage.success('تم إضافة الوجبة المقيدة بنجاح');
        this.fetchRestrictedMeals();
        this.selectedMeal = null;
      } catch (error) {
        ElMessage.error('فشل في إضافة الوجبة المقيدة');
      }
    },
    async removeMealRestriction(mealId) {
      try {
        await api.delete(`/students-restricted-meals/${this.selectedStudent}/restricted-meals/${mealId}`);
        ElMessage.success('تم إزالة الوجبة المقيدة بنجاح');
        this.fetchRestrictedMeals();
      } catch (error) {
        ElMessage.error('فشل في إزالة الوجبة المقيدة');
      }
    },
  },
  mounted() {
    this.fetchStudents();
    this.fetchMeals();
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
