<template>
    <div>
      <!-- Filter Form -->
      <el-form :model="filters" label-width="120px">
        <el-form-item label="Teacher Type">
          <el-select v-model="filters.teacher_type_id" placeholder="Select Teacher Type">
            <el-option v-for="type in teacherTypes" :key="type.id" :label="type.type" :value="type.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="Class">
          <el-select v-model="filters.class_id" placeholder="Select Class">
            <el-option v-for="classItem in classes" :key="classItem.id" :label="classItem.name" :value="classItem.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="Section">
            <el-select v-model="filters.section_id" placeholder="Select Section">
              <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id" />
            </el-select>
          </el-form-item>
          
        <el-form-item label="Gender">
          <el-select v-model="filters.gender" placeholder="Select Gender">
            <el-option label="Male" value="male" />
            <el-option label="Female" value="female" />
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="fetchEmployees">Filter</el-button>
        <el-button @click="printReport">Print Report</el-button>
      </el-form>
  
      <!-- Employee List -->
      <el-table v-loading="loading" :data="employees" border>
        <el-table-column sortable  prop="name" label="Name" />
        <el-table-column prop="phone_number" label="Phone Number" />
        <el-table-column prop="gender" label="Gender" />
        <el-table-column label="Teacher Types">
            <template v-slot="scope">
              <span>
                {{ scope.row.teacher_types ? scope.row.teacher_types.map(type => type.type).join(', ') : 'N/A' }}
              </span>
            </template>
          </el-table-column>
        <el-table-column label="Classes">
          <template v-slot="scope">
            <span>{{ scope.row.classes.map(cls => cls.name).join(', ') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Sections">
          <template v-slot="scope">
            <span>{{ scope.row.sections.map(section => section.name).join(', ') }}</span>
          </template>
        </el-table-column>
      </el-table>
      
  
      <!-- Pagination -->
      <el-pagination
        v-if="employees"
        @current-change="handlePageChange"
        :current-page="filters.page"
        :page-size="filters.per_page"
        layout="total, prev, pager, next"
        :total="employees.total"
      />
    </div>
  </template>
  
  <script>

  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import axios from 'axios';
  import  TajawalBoldBase64  from "@/assets/fonts/TajawalBold.js";  // Assuming you have a valid Base64 font string in this file.
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf"; // Ensure this path is correct
import AmiriRegular from "@/assets/fonts/Amiri-Regular.ttf"; // Ensure this path is correct

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Tajawal: {
    normal: 'Tajawal-Bold.ttf',
    bold: 'Tajawal-Bold.ttf',
  },
  Amiri: {
    normal: AmiriRegular,
    bold: AmiriRegular,
    italics: AmiriRegular,
    bolditalics: AmiriRegular,
  },
};


const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  export default {
    data() {
      return {
        employees: [],
        loading: false,
        teacherTypes: [],
        classes: [],
        sections: [],
        
        filters: {
          teacher_type_id: null,
          class_id: null,
          section_id: null,
          gender: null,
          page: 1,
          per_page: 10,
        },
      };
    },
    methods: {
        fetchSections(classId) {
    api
      .get(`/classes/${classId}/sections`)
      .then((response) => {
        this.sections = response.data;
      })
      .catch((error) => {
        console.error('Error fetching sections:', error);
      });
  },
  fetchEmployees() {
   
    api
      .get('/employees-filtered', { params: this.filters })
      .then((response) => {
        // Update this line to correctly assign the employees array
        this.loading = true;
        this.employees = response.data.data; // Access the data property
        this.loading = false
      })
      .catch((error) => {
        console.error(error);
      }).finally(
       
      )
  },
      handlePageChange(page) {
        this.filters.page = page;
        this.fetchEmployees();
      },
      printReport() {
  api
    .get('/employees/report', { params: this.filters })
    .then((response) => {
      const { totalEmployees, employeesWithTeacherType, employeesWithoutTeacherType, employees } = response.data;

      const docDefinition = {
        pageMargins: [40, 80, 40, 60],
        defaultStyle: {
          font: 'Tajawal',
          alignment: 'right',
          fontSize: 14,
        },
        header: {
          text: 'Employee Report',
          style: 'header',
        },
        content: [
          {
            text: `Total Employees: ${totalEmployees}`,
            margin: [0, 10, 0, 0],
          },
          {
            text: `Employees with Teacher Type: ${employeesWithTeacherType}`,
            margin: [0, 5, 0, 0],
          },
          {
            text: `Employees without Teacher Type: ${employeesWithoutTeacherType}`,
            margin: [0, 5, 0, 20],
          },
          {
            table: {
              widths: ['*', '*', '*', '*', '*', '*'], // Ensure the correct number of columns
              body: [
                [
                  { text: 'Name', style: 'tableHeader' },
                  { text: 'Phone Number', style: 'tableHeader' },
                  { text: 'Gender', style: 'tableHeader' },
                  { text: 'Teacher Types', style: 'tableHeader' },
                  { text: 'Classes', style: 'tableHeader' },
                  { text: 'Sections', style: 'tableHeader' },
                ],
                ...employees.map((employee) => [
                  employee.name,
                  employee.phone_number,
                  employee.gender,
                  employee.teacher_types ? employee.teacher_types.map(type => type.type).join(', ') : 'N/A', // Use optional chaining
                  employee.classes ? employee.classes.map(cls => cls.name).join(', ') : 'N/A', // Use optional chaining
                  employee.sections ? employee.sections.map(section => section.name).join(', ') : 'N/A', // Use optional chaining
                ]),
              ],
            },
            layout: {
              fillColor: (rowIndex) => (rowIndex === 0 ? '#f2f2f2' : null),
              hLineColor: () => '#cccccc',
              vLineColor: () => '#cccccc',
              paddingLeft: (i) => (i === 0 ? 10 : 5),
              paddingRight: () => 5,
              paddingTop: () => 5,
              paddingBottom: () => 5,
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'center',
            margin: [0, 20, 0, 20],
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black',
            alignment: 'center',
            fillColor: '#d9d9d9',
            margin: [5, 5],
          },
        },
      };

      // Create and download the PDF
      pdfMake.createPdf(docDefinition).download('employee_report.pdf');
    })
    .catch((error) => {
      console.error(error);
    });
}



},
    mounted() {
      // Fetch data for filters (teacher types, classes, sections)
      api.get('/teacher-types').then((response) => {
        this.teacherTypes = response.data;
      });
      api.get('/classes').then((response) => {
        this.classes = response.data;
      });
  
  
      this.fetchEmployees(); // Fetch initial data
    },
    watch: {
  'filters.class_id'(newClassId) {
    if (newClassId) {
      this.fetchSections(newClassId);
    } else {
      this.sections = []; // Clear sections if no class is selected
    }
  }
},

  };
  </script>
  