<template>
  <div class="container">
    <el-card>
      <h3 class="text-center">Parent Wallets</h3>
      <el-input placeholder="Search by Parent Name" v-model="searchQuery" @input="fetchWallets"></el-input>
      <div class="col-12 d-flex justify-content-end">
        <el-button type="primary" @click="openCreateDialog">Create Wallet +</el-button>
      </div>

      <el-table :data="wallets" style="width: 100%">
        <el-table-column prop="parent_id" label="Parent ID" />
        <el-table-column prop="parent.first_name" label="Parent first name" />
        <el-table-column prop="parent.last_name" label="Parent last name" />


        <el-table-column prop="balance" label="Balance" />
        <el-table-column>
          <template #header>
            <span>Actions</span>
          </template>
          <template #default="scope">
            <div class="d-flex justify-content-around">
              <el-button @click="editWallet(scope.row)" type="text">Edit</el-button>
              <el-button @click="deleteWallet(scope.row.id)" type="danger">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- Create Wallet Dialog -->
      <el-dialog title="Create Wallet" v-model="isCreateDialogVisible">
        <el-form :model="newWallet" ref="form">
          <el-form-item label="Parent ID">
            <el-select filterable v-model="newWallet.parent_id" placeholder="Select Parent">
              <el-option v-for="parent in parents" :key="parent.id" :label="parent.first_name + ' ' + parent.last_name"
                :value="parent.id" />
            </el-select>
            <span v-if="formErrors.parent_id" class="error-message">{{ formErrors.parent_id[0] }}</span>
          </el-form-item>
          <el-form-item label="Balance">
            <el-input v-model.number="newWallet.balance" type="number" placeholder="Balance" />
            <span v-if="formErrors.balance" class="error-message">{{ formErrors.balance[0] }}</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isCreateDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="createWallet">Create</el-button>
        </span>
      </el-dialog>

      <!-- Edit Wallet Dialog -->
      <el-dialog title="Edit Wallet" v-model="isEditDialogVisible">
        <el-form :model="currentWallet" ref="editForm">
          <p>Parent name : {{ currentWallet.parent.first_name + ' ' + currentWallet.parent.last_name }}</p>
          <el-form-item label="Balance">
            <el-input v-model.number="currentWallet.balance" type="number" placeholder="Balance" />
          </el-form-item>
          <span v-if="editFormErrors.balance" class="error-message">{{ editFormErrors.balance[0] }}</span>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isEditDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="updateWallet">Update</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ElCard, ElInput, ElButton, ElTable, ElTableColumn, ElSelect, ElOption, ElForm, ElFormItem, ElDialog } from 'element-plus';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElInput,
    ElButton,
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElForm,
    ElFormItem,
    ElDialog,
  },
  data() {
    return {
      wallets: [],
      parents: [],
      newWallet: {
        parent_id: '',
        balance: '',
      },
      currentWallet: {},
      formErrors: {},
      editFormErrors: {},
      isCreateDialogVisible: false,
      isEditDialogVisible: false,
      searchQuery: '', // New property for the search query
    };
  },
  methods: {
    openCreateDialog() {
      this.parents = [];
      this.fetchParents();
      this.newWallet = { parent_id: '', balance: '' };
      this.formErrors = {};
      this.isCreateDialogVisible = true;
    },
    editWallet(wallet) {
      this.editFormErrors = {};
      this.currentWallet = { ...wallet };
      this.isEditDialogVisible = true; // Open the edit dialog
    },
    async fetchParents() {
      this.parents = [];
      try {
        const response = await api.get('/parents-without-wallets');
        this.parents = response.data;
      } catch (error) {
        console.error("Error fetching parents:", error);
      }
    },
    async fetchWallets() {
      try {
        const response = await api.get('/parent-wallets', {
          params: { search: this.searchQuery }, // Pass the search query
        });
        this.wallets = response.data;
      } catch (error) {
        console.error("Error fetching wallets:", error);
      }
    },
    async createWallet() {
      this.formErrors = {};
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.post('/parent-wallets', this.newWallet)
            .then(() => {
              this.fetchWallets();
              this.isCreateDialogVisible = false;
            })
            .catch(error => {
              if (error.response && error.response.data.errors) {
                this.formErrors = error.response.data.errors;
              }
              console.error("Error creating wallet:", error);
            });
        }
      });
    },
    async updateWallet() {
      this.editFormErrors = {};
      try {
        await api.put(`/parent-wallets/${this.currentWallet.id}`, this.currentWallet);
        this.fetchWallets();
        this.isEditDialogVisible = false; // Close the edit dialog
      } catch (error) {
        console.error("Error updating wallet:", error);
        this.editFormErrors = error.response.data.errors;
      }
    },
    async deleteWallet(id) {
      try {
        await api.delete(`/parent-wallets/${id}`);
        this.fetchWallets();
      } catch (error) {
        console.error("Error deleting wallet:", error);
      }
    },
  },
  mounted() {
    this.fetchParents();
    this.fetchWallets();
  },
};
</script>

<style scoped>
.container {
  margin: 20px;
}

.error-message {
  color: red;
  font-size: 12px;
}
</style>
