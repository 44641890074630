<template>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
                <div class="d-flex justify-content-around">
                  <h6 class="text-white text-capitalize ps-3">Employee Types</h6>
                  <el-input
                    style="width: 400px;"
                    v-model="searchQuery"
                    placeholder="Search by Name"
                    class="search-input"
                  ></el-input>
                  <el-button @click="openCreateDialog" type="success" circle >
                    <el-icon style="vertical-align: middle">
                      <Document />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </div>
            <div>
              <el-table :data="pagedEmployeeTypes" style="width: 100%">
                <el-table-column property="name" label="Name" width="150"/>
                <el-table-column label="Actions" width="500">
                  <template #default="scope">
                    <el-button @click="openEditDialog(scope.row)" type="warning" size="mini">Edit</el-button>
                    <el-button @click="deleteEmployeeType(scope.row.id)" type="danger" size="mini">Delete</el-button>
                  </template>
                </el-table-column>
              </el-table>
  
              <div class="d-flex justify-content-center mt-4">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  :total="total"
                  layout="prev, pager, next"
                  background
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Create/Edit Dialog -->
      <el-dialog v-model="dialogVisible" title="Employee Type">
        <el-form :model="form">
          <el-form-item label="Name" :label-width="formLabelWidth">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveEmployeeType">Save</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { Document } from '@element-plus/icons-vue';
  import axios from 'axios';
  import { ElMessage } from 'element-plus';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
  export default {
    components:{
      Document
    },
    data() {
      return {
        employeeTypes: [],
        searchQuery: '',
        dialogVisible: false,
        form: {
          id: null,
          name: ''
        },
        currentPage: 1,
        pageSize: 10,
        total: 0, // To handle total number of employee types
        formLabelWidth: '120px'
      };
    },
    computed: {
      filteredEmployeeTypes() {
        if (this.searchQuery) {
          return this.employeeTypes.filter(employeeType => 
            employeeType.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
        return this.employeeTypes;
      },
      pagedEmployeeTypes() {
        const start = (this.currentPage - 1) * this.pageSize;
        return this.filteredEmployeeTypes.slice(start, start + this.pageSize);
      }
    },
    methods: {
      fetchEmployeeTypes() {
        api.get('/employee-types', {
          params: { page: this.currentPage, per_page: this.pageSize }
        })
        .then(response => {
          this.employeeTypes = response.data.data; // Extract employee types from the paginated response
          this.total = response.data.total; // Set the total number of records for pagination
        })
        .catch(error => {
          console.error(error);
        });
      },
      handleCurrentChange(page) {
        this.currentPage = page;
        this.fetchEmployeeTypes(); // Fetch new data for the selected page
      },
      openCreateDialog() {
        this.form = { id: null, name: '' };
        this.dialogVisible = true;
      },
      openEditDialog(employeeType) {
        this.form = { ...employeeType };
        this.dialogVisible = true;
      },
      saveEmployeeType() {
        if (this.form.id) {
          // Update
          api.put(`/employee-types/${this.form.id}`, this.form)
            .then(response => {
              ElMessage.success(response.data.message);
              this.fetchEmployeeTypes();
              this.dialogVisible = false;
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          // Create
          api.post('/employee-types', this.form)
            .then(response => {
              ElMessage.success(response.data.message);
              this.fetchEmployeeTypes();
              this.dialogVisible = false;
            })
            .catch(error => {
              console.error(error);
            });
        }
      },
      deleteEmployeeType(id) {
        api.delete(`/employee-types/${id}`)
          .then(response => {
            ElMessage.success(response.data.message);
            this.fetchEmployeeTypes();
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    mounted() {
      this.fetchEmployeeTypes();
    }
  };
  </script>
  
  
  
  <style scoped>
  .bg-header-table {
    background-color: #00bfa5;
  }
  
  .search-input {
    margin-left: auto;
  }
  
  .dialog-footer {
    text-align: right;
  }
  </style>
  