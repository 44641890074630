<template>
  <div>
    <el-input
      v-model="searchQuery"
      :placeholder="$t('searchPlaceholder')"
      @input="fetchUsers"
      style="margin-bottom: 10px; width: 300px;"
    ></el-input>

    <el-button type="primary" @click="openCreateDialog">{{ $t('createUser') }}</el-button>

    <el-table :data="users.data" stripe style="max-width: 100%;">
      <el-table-column prop="name" :label="$t('name')" />
      <el-table-column prop="phone" :label="$t('phone')" />
      <el-table-column prop="username" :label="$t('username')" />
      <el-table-column prop="email" :label="$t('email')" />
      <el-table-column prop="user_type" :label="$t('userType')" />
      <el-table-column :label="$t('actions')">
        <template #default="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)">{{ $t('edit') }}</el-button>
          <el-button type="danger" @click="confirmDelete(scope.row.id)">{{ $t('delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="users.total > 0"
      background
      layout="prev, pager, next"
      :total="users.total"
      :page-size="10"
      :current-page="currentPage"
      @current-change="handlePageChange"
    />

    <el-dialog :title="$t('userFormTitle')" v-model="dialogVisible">
      <el-form :model="form" ref="formRef" :rules="rules">
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item :label="$t('phone')" prop="phone">
          <el-input v-model="form.phone" />
        </el-form-item>
        <el-form-item :label="$t('username')" prop="username">
          <el-input v-model="form.username" />
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item :label="$t('address')">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item :label="$t('password')" prop="password">
          <el-input type="password" v-model="form.password" :placeholder="$t('password')" />
        </el-form-item>
        <el-form-item :label="$t('userType')" prop="user_type">
          <el-select v-model="form.user_type">
            <el-option v-for="type in userTypes" :key="type" :label="type" :value="type" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="submitForm">{{ $t('submit') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import axios from 'axios';
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      console.log("Request config:", config);
      return config;
    }, 
    (error) => {
      console.error("Interceptor error:", error);
      return Promise.reject(error);
    }
  );
export default {
  data() {
    return {
      users: {
        data: [],
        total: 0,
      },
      currentPage: 1,
      searchQuery: '',
      dialogVisible: false,
      isEdit: false,
      form: {
        name: '',
        phone: '',
        username: '',
        email: '',
        address: '',
        password: 'alialiali',
        user_type: '',
      },
      userTypes: ['admin', 'teacher', 'staff', 'student', 'parent', 'driver', 'financial', 'doctor', 'moder', 'hr', 'prin', 'student_m','cashier','student supervisor'],
      rules: {
        name: [{ required: true, message: 'Please input the name', trigger: 'blur' }],
        phone: [{ required: true, message: 'Please input the phone', trigger: 'blur' }],
        username: [{ required: true, message: 'Please input the username', trigger: 'blur' }],
        email: [{ required: true, message: 'Please input the email', trigger: 'blur' }],
        user_type: [{ required: true, message: 'Please select the user type', trigger: 'change' }],
      },
    };
  },
  methods: {
    async fetchUsers(page = 1) {
      this.currentPage = page; // Update current page
      try {
        const response = await api.get('/users', {
          params: {
            page: this.currentPage,
            name: this.searchQuery,
          },
        });
        this.users = response.data;
      } catch (error) {
        this.$message.error('Failed to fetch users.');
      }
    },
    handlePageChange(page) {
      this.fetchUsers(page);
    },
    openCreateDialog() {
      this.isEdit = false;
      this.dialogVisible = true;
      this.resetForm();
    },
    openEditDialog(user) {
      this.isEdit = true;
      this.dialogVisible = true;
      this.form = { ...user }; // Use spread operator to copy user data
    },
    async submitForm() {
      try {
        const payload = { ...this.form };

        if (!payload.password) {
       delete payload.password; // Avoid sending an empty password field
     }
        if (this.isEdit) {
          await api.put(`/users/${this.form.id}`, this.form);
          this.$message.success('User updated successfully.');
        } else {
          await api.post('/users', this.form);
          this.$message.success('User created successfully.');
        }
        this.dialogVisible = false;
        this.fetchUsers(); // Refresh the list
      } catch (error) {
        this.$message.error('Failed to save user.');
      }
    },
    async confirmDelete(id) {
      this.$confirm('Are you sure you want to delete this user?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
        .then(async () => {
          await api.delete(`/users/${id}`);
          this.$message.success('User deleted successfully.');
          this.fetchUsers(); // Refresh the list
        })
        .catch(() => {
          this.$message.info('Delete action canceled.');
        });
    },
    resetForm() {
      this.form = {
        name: '',
        phone: '',
        username: '',
        email: '',
        address: '',
        password: '',
        user_type: '',
      };
      this.$refs.formRef.resetFields();
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
/* Add any styles you need */
.el-table {
  max-height: 600px;
  width: 100%;
}
</style>
