<template>
    <router-link
      :to="href"
      class="card mb-2 text-center d-flex flex-column align-items-center justify-content-center"
      :class="directionReverse ? reverseDirection : ''"
      :style="{ width: '140px', height: '140px', borderRadius: '50%', overflow: 'hidden', textDecoration: 'none' }"
    >
      <div :class="{'dark-mode': isDarkMode}" class="card-header p-2 d-flex flex-column align-items-center">
        <div v-if="icon && !image" class="icon icon-lg text-center position-relative">
          <i class="material-icons opacity-10" :class="icon.color" aria-hidden="true">
            {{ icon.name }}
          </i>
        </div>
        <div v-else-if="image" class="image-container text-center position-relative mb-2">
          <img :src="image" alt="icon image" class="img-fluid rounded-circle" style="width: 70px; height: 70px; object-fit: cover;" />
        </div>
        <div class="title-container text-center">
          <h6 :class="{'dark-mode': isDarkMode}">{{ title.text }}</h6>
        </div>
      </div>
    </router-link>
  </template>
  
  <script>
  import { mapGetters, mapState } from "vuex";
  export default {
    name: "MiniStatisticsCard",
    props: {
      href: {
        type: String,
        required: true, // Ensures href is provided when the component is used
      },
      title: {
        type: Object,
        required: true,
      },
      detail: {
        type: String,
        default: "",
      },
      icon: {
        type: Object,
        default: () => ({
          color: "text-white",
          background: "success",
        }),
      },
      image: {
        type: String,
        default: null,
      },
      directionReverse: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(["isRTL"]),
      ...mapGetters(["isDarkMode"]),
    },
  };
  </script>
  
  <style scoped>
  @font-face {
    font-family: 'TajawalBold';
    src: url('../../../public/fonts/TajawalBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: none !important;
    color: inherit; /* Inherit color from parent */
    text-decoration: none; /* Remove underline */
  }
  .image-container img {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .title-container {
    padding: 10px;
  }
  .text-sm {
    font-size: 0.875rem;
  }
  h6 {
    font-family: 'TajawalBold' !important;
  }
  </style>
  