<template>
  <img class="wave" src="img/page-turner.svg">
	<div class="container">
    <div class="login-content">
			<form @submit.prevent="handleSubmit" role="form">
				<img src="/logo-school-one.png">
				<h2 class="title">Welcome</h2>
           		<div class="input-div one">
           		   <div class="i">
           		   		<i class="fas fa-user"></i>
           		   </div>
           		   <div class="div">
           		   		<h5>Username</h5>
           		   		<input v-model="login" type="text" class="input">
           		   </div>
           		</div>
           		<div class="input-div pass">
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Password</h5>
           		    	<input v-model="password" type="password" class="input">
            	   </div>
            	</div>
            	<!-- <a href="#">Forgot Password?</a> -->
            	<input type="submit" class="btn" value="Login">
            </form>
        </div>
		<div class="img">
			<img src="img/bg.svg">
		</div>

    </div>
    
</template>

<script>
import axios from 'axios';
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import { mapMutations } from "vuex";

export default {
  name: "sign-in",
  components: {
    MaterialSwitch,
  },
  data() {
    return {
      login: '',
      password: '',
      error: null,
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig", "SET_AUTH"]),
    async handleSubmit() {
        try {
            this.error = null;

            console.log('Attempting login with:', { login: this.login, password: this.password });

            // Make the API request
            const response = await axios.post('https://cale.caledonian.ly/api/login', {
                login: this.login,
                password: this.password,
            });

            console.log('API Response:', response.data);

            // Extract token and user data
            const { token, user } = response.data;

            console.log(user)
            // Check if user is defined
            if (!user) {
                throw new Error('User information is missing in the response');
            }

            // Set authentication details in local storage
            localStorage.setItem('access_token', token);
            localStorage.setItem('user_type', user.user_type);
            localStorage.setItem('userId', user.id);
            localStorage.setItem('username', user.username);
            localStorage.setItem('isLoggedIn', 'true'); // Store login state

            // Notify user of successful login
            this.$notify({
                title: 'Login Successful',
                message: `مرحباً بعودتك 😙, ${user.username}!`,
                type: 'success',
            });

            // Reload the page if it hasn't been done yet
            if (!localStorage.getItem('has_reloaded')) {
                localStorage.setItem('has_reloaded', 'true');
                setTimeout(()=>{
                  window.location.reload();
                },4000)
            } else {
                // If reloaded, clear the reload flag and redirect to dashboard
                localStorage.setItem('has_reloaded', 'true');
                setTimeout(()=>{
                  window.location.reload();
                },4000)
                this.$router.push({ name: 'Dashboard' }).catch((error) => {
                    console.error('Navigation error:', error);
                });
            }

        } catch (error) {
            console.error('Error details:', error); // Log the complete error object
            this.error = error.response?.data?.message || 'Invalid login credentials';
        }
    },




  },
  mounted() {
  // Check if the user is logged in and needs to be redirected after reload
  if (localStorage.getItem('isLoggedIn') === 'true') {
    // Redirect to the dashboard if already logged in
    this.$router.push({ name: 'Dashboard' }).catch((error) => {
      console.error('Navigation error:', error);
    })
  }

  const inputs = document.querySelectorAll(".input");


function addcl(){
	let parent = this.parentNode.parentNode;
	parent.classList.add("focus");
}

function remcl(){
	let parent = this.parentNode.parentNode;
	if(this.value == ""){
		parent.classList.remove("focus");
	}
}


inputs.forEach(input => {
	input.addEventListener("focus", addcl);
	input.addEventListener("blur", remcl);
});

}
};
</script>

<style lang="css" scoped>
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}

.wave{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}

.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0 2rem;
}

.img{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-content{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.img img{
	width: 500px;
}

form{
	width: 360px;
}

.login-content img{
    height: 100px;
}

.login-content h2{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content .input-div{
	position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one{
	margin-top: 0;
}

.i{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i{
	transition: .3s;
}

.input-div > div{
    position: relative;
	height: 45px;
}

.input-div > div > h5{
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}

.input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}

.input-div:before{
	right: 50%;
}

.input-div:after{
	left: 50%;
}

.input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.input-div.focus > div > h5{
	top: -5px;
	font-size: 15px;
}

.input-div.focus > .i > i{
	color: #38d39f;
}

.input-div > div > input{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass{
	margin-bottom: 4px;
}

a{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

a:hover{
	color: #38d39f;
}

.btn{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #1100ff, #1100ff, #928bff);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.btn:hover{
	background-position: right;
}


@media screen and (max-width: 1050px){
	.container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	form{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.img img{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}
</style>
