<template>
  <div class="container">
    <el-card class="p-4">
      <h3 class="text-center">Meal Categories</h3>
      <div class="p-3 d-flex flex-row border-bottom border-secondary justify-content-end">
        <el-button type="primary" @click="openCategoryDialog">Create Category +</el-button>
      </div>
      <el-table :data="categories" style="width: 100%">
        <el-table-column prop="name" label="Category Name" />
        <el-table-column>
          <template #header>
            <span>Actions</span>
          </template>
          <template #default="scope">
            <div>
              <el-button @click="editCategory(scope.row)" type="text">Edit</el-button>
              <el-button @click="deleteCategory(scope.row.id)" type="danger">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- Create/Edit Category Dialog -->
      <el-dialog title="Category" v-model="isCategoryDialogVisible">
        <el-form :model="currentCategory" ref="categoryForm">
          <el-form-item label="Category Name">
            <el-input v-model="currentCategory.name" />
            <p v-if="formErrors.name" class="text-danger">
              {{ formErrors.name[0] }}
            </p>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isCategoryDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveCategory">{{ isEditingCategory ? 'Update' : 'Create' }}</el-button>
        </span>
      </el-dialog>
    </el-card>
    <el-card class="p-4 mt-3">

      <h3 class="text-center">Meals</h3>
      <div class="p-3 d-flex flex-row border-bottom border-secondary">
        <div class="col-6 d-flex flex-row ">
          <el-input placeholder="Search by Meal Name" v-model="searchTerm" @input="fetchMeals" />

          <el-select v-model="selectedCategory" placeholder="Select a category" @change="filterMeals">
            <el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id" />
          </el-select>
          <!--  -->
          <!--  -->
        </div>

        <div class="col-6 d-flex flex-row  justify-content-end">
          <el-button type="primary" @click="openMealDialog">Create Meal +</el-button>
        </div>



      </div>



      <el-table :data="filteredMeals" style="width: 100%">
        <el-table-column prop="name" label="Meal Name" />
        <el-table-column prop="category.name" label="Category" />
        <el-table-column prop="price" label="Price" />
        <el-table-column>
          <template #header>
            <span>Actions</span>
          </template>
          <template #default="scope">
            <div>
              <el-button @click="editMeal(scope.row)" type="text">Edit</el-button>
              <el-button @click="deleteMeal(scope.row.id)" type="danger">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- Create/Edit Meal Dialog -->
      <el-dialog title="Meal" v-model="isMealDialogVisible">
        <el-form :model="currentMeal" ref="mealForm">
          <el-form-item label="Meal Name">
            <el-input v-model="currentMeal.name" />
            <p v-if="formErrors.name" class="text-danger">
              {{ formErrors.name[0] }}
            </p>
          </el-form-item>
          <el-form-item label="Price">
            <el-input v-model.number="currentMeal.price" type="number" />
            <p v-if="formErrors.price" class="text-danger">
              {{ formErrors.price[0] }}
            </p>
          </el-form-item>
          <el-form-item label="Category" :error="formErrors.category">
            <el-select filterable v-model="currentMeal.category_id" placeholder="Select Category">
              <el-option v-for="category in categories" :key="category.id" :label="category.name"
                :value="category.id" />
            </el-select>
            <p v-if="formErrors.category_id" class="text-danger">
              {{ formErrors.category_id[0] }}
            </p>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isMealDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveMeal">{{ isEditingMeal ? 'Update' : 'Create' }}</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ElButton, ElTable, ElCard, ElTableColumn, ElDialog, ElForm, ElFormItem, ElInput, ElSelect, ElOption } from 'element-plus';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  components: {
    ElButton,
    ElTable,
    ElTableColumn,
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElCard,
  },
  data() {
    return {
      categories: [],
      meals: [],
      filteredMeals: [],
      selectedCategory: null,
      currentCategory: { id: null, name: '' },
      currentMeal: { id: null, name: '', price: '', category_id: null },
      isCategoryDialogVisible: false,
      isMealDialogVisible: false,
      isEditingCategory: false,
      isEditingMeal: false,
      formErrors: {},
      searchTerm: "",
    };
  },
  methods: {
    async fetchCategories() {
      const response = await api.get('/categories');
      this.categories = response.data;
    },
    async fetchMeals() {
      const response = await api.get('/meals', {
        params: {
          category_id: this.selectedCategory,
          search: this.searchTerm, // Pass search term to API
        }
      });
      this.meals = response.data;
      this.filteredMeals = this.meals; // Initially show all meals
    },
    filterMeals() {
      if (this.selectedCategory) {
        this.filteredMeals = this.meals.filter(meal => meal.category_id === this.selectedCategory);
      } else {
        this.filteredMeals = this.meals; // Show all meals if no category is selected
      }
    },
    openCategoryDialog() {
      this.formErrors = {}; // Reset errors
      this.isEditingCategory = false;
      this.currentCategory = { id: null, name: '' };

      this.isCategoryDialogVisible = true;
    },
    async saveCategory() {
      this.formErrors = {}; // Reset errors
      try {
        if (this.isEditingCategory) {
          await api.put(`/categories/${this.currentCategory.id}`, this.currentCategory);
        } else {
          await api.post('/categories-store', this.currentCategory);
        }
        this.isCategoryDialogVisible = false;
        this.fetchCategories();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors;
        }
      }
    },
    editCategory(category) {

      this.formErrors = {}; // Reset errors
      this.isEditingCategory = true;
      this.currentCategory = { ...category };
      this.isCategoryDialogVisible = true;
    },
    async deleteCategory(id) {
      await api.delete(`/categories/${id}`);
      this.fetchCategories();
    },
    openMealDialog() {
      this.isEditingMeal = false;
      this.currentMeal = { id: null, name: '', price: '', category_id: null };
      this.formErrors = {}; // Reset errors
      this.isMealDialogVisible = true;
    },
    async saveMeal() {
      this.formErrors = {}; // Reset errors
      try {
        if (this.isEditingMeal) {
          await api.put(`/meals/${this.currentMeal.id}`, this.currentMeal);
        } else {
          await api.post('/meals', this.currentMeal);
        }
        this.isMealDialogVisible = false;
        this.fetchMeals();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors;
        }
      }
    },
    editMeal(meal) {
      this.formErrors = {} // Reset errors
      this.isEditingMeal = true;
      this.currentMeal = { ...meal };
      this.isMealDialogVisible = true;
    },
    async deleteMeal(id) {
      await api.delete(`/meals/${id}`);
      this.fetchMeals();
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchMeals();
  },
};
</script>

<style scoped>
.container {
  margin: 20px;
}
</style>
