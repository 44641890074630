<!--
=========================================================
* Vue Material Dashboard 2 - v3.1.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" />
  <main :class="{ 'dark-mode': isDarkMode }" id="app-background"
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :minNav="navbarMinimize" v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />
  </main>
</template>
<script>

import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode']),
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }

    this.$store.state.isRTL = true;
    document.querySelector("html").setAttribute("lang", "ar");
    document.querySelector("html").setAttribute("dir", "rtl");
    document.querySelector("#app").classList.add("rtl");
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }

    this.$store.state.isRTL = true;
    document.querySelector("html").setAttribute("lang", "ar");
    document.querySelector("html").setAttribute("dir", "rtl");
    document.querySelector("#app").classList.add("rtl");
  },


  beforeUnmount() {
    this.$store.state.isRTL = false;
    document.querySelector("html").removeAttribute("lang");
    document.querySelector("html").removeAttribute("dir");
    document.querySelector("#app").classList.remove("rtl");
  },
  watch: {
    isDarkMode(newVal) {
      const appElement = document.getElementById('app');
      if (newVal) {
        appElement.classList.add('dark-mode');
      } else {
        appElement.classList.remove('dark-mode');
      }
    }
  },
  mounted() {
    // Ensure the class is set when the component is mounted
    const appElement = document.getElementById('app');
    if (this.isDarkMode) {
      appElement.classList.add('dark-mode');
    }
  }
};
</script>
<style>
#app:not(.dark-mode) {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 10;
  overflow: scroll;
  
scrollbar-color: linear-gradient(to right, #434343 0%, black 100%);
scrollbar-width: thin;
width: 100%;

background-color: #ffffff;
  z-index: 1; /* Ensure it stays behind other content */
}

.bg-header-table:not(.dark-mode) {
  background-image: linear-gradient(to right, #434343 0%, black 100%);
}

#app.dark-mode {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 10;
  overflow: scroll;
  scrollbar-color: linear-gradient(to right, #434343 0%, black 100%);
  scrollbar-width: thin;
  width: 100%;
  background-color: #121212;
  z-index: 1;
  /* Ensure it stays behind other content */
}

.bg-aside {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 63rem;
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  z-index: -1;
  color: #261C2C;

}

.bg-aside.dark-mode {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.40) 120%) #989898;
  background-blend-mode: multiply, multiply;
}

.card-header:not(.dark-mode) {
  background-color: transparent !important;
  color: #261C2C !important;
}

.card .card-footer.dark-mode {
  background-color: #282828;

}

hr.horizontal.dark-mode {
  background-color: white !important;
}

h4:is(.dark-mode) {
  color: #8b8b8b !important;
}

.card .card-footer:not(.dark-mode) {
  background-color: transparent !important;
}

.card-header.dark-mode {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #282828;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);

  color: #ffffff;
}

.card:not(.dark-mode) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(124, 69, 255, .2) !important;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.card:is(.dark-mode) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #282828;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  color: #ffffff;
}

.navbar-vertical .navbar-nav .nav-link:not(.dark-mode) {
  color: #261C2C !important;
}

.nav-link.dark-mode {
  color: white;
}


.navbar-vertical .navbar-nav .nav-item:hover {
  background-color: #4dc0da;

}

.nav-link:hover {
  color: rgb(255, 255, 255) !important;
}
</style>