<template>
  <div class="container">
    <h2>Import Attendance Data</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-item">
        <label for="file">Upload Excel File</label>
        <input
          type="file"
          id="file"
          @change="handleFileChange"
          accept=".xlsx"
        />
        <span v-if="fileList.length > 0">
          <button type="button" @click="clearFileList">Remove File</button>
        </span>
      </div>

      <button type="submit" :disabled="!fileList.length" :class="{'loading': loading}">
        Import Attendance
      </button>

      <div v-if="responseMessage" class="response-message">
        <p>{{ responseMessage }}</p>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token'); // Get token from local storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  data() {
    return {
      formData: {
        file: null,
      },
      fileList: [],
      loading: false,
      responseMessage: '',
      uploadUrl: '/import-attendance',
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const isExcel =
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isExcel) {
          alert('You can only upload Excel files.');
        } else {
          this.fileList = [file]; // Add file to the list
        }
      }
    },

    handleSubmit() {
      if (this.fileList.length > 0) {
        this.uploadFile(this.fileList[0]);
      }
    },

    async uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  try {
    this.loading = true;
    console.log('Sending request with token:', localStorage.getItem('access_token'));
    const response = await api.post(this.uploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    console.log(response); // Log response for debugging

    this.loading = false;
    this.responseMessage = response.data.message || 'Import successful';
    alert('Attendance data imported successfully!');
  } catch (error) {
    this.loading = false;
    console.error('Error:', error.response || error); // Log the error details
    this.responseMessage = 'An error occurred during the import.';
  }
},

    clearFileList() {
      this.fileList = [];
    },
  },
};

</script>
<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-item {
  margin-bottom: 20px;
}

button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}

.response-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #e0ffe0;
  border: 1px solid #00cc00;
  color: #008800;
}

</style>