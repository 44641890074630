<template>
    <div>
      <h1>Student Reports</h1>
  
      <!-- Filter Section using Element Plus components -->
      <div style="margin-bottom: 20px">
        <el-form :inline="true">
          <el-form-item label="Filter by Gender:">
            <el-select v-model="genderFilter" placeholder="Select Gender" @change="fetchStudentsByGender" style="width: 200px">
              <el-option label="All" value=""></el-option>
              <el-option label="Male" value="male"></el-option>
              <el-option label="Female" value="female"></el-option>
            </el-select>
          </el-form-item>
  
          <el-form-item label="Filter by Class:">
            <el-select v-model="classId" placeholder="Select Class" @change="onClassChange" style="width: 200px">
              <el-option v-for="classRoom in classes" :key="classRoom.id" :label="classRoom.name" :value="classRoom.id"></el-option>
            </el-select>
          </el-form-item>
  
          <el-form-item label="Filter by Section:">
            <el-select v-model="sectionId" placeholder="Select Section" @change="fetchClassStudents" style="width: 200px">
              <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
  
      <!-- Gender Toggle Buttons -->
      <div style="display: flex; justify-content: space-between; max-width: 200px; margin: 0 auto 20px;">
        <el-button @click="showGender('male')" :disabled="genderFilter === 'male'">Show Male</el-button>
        <el-button @click="showGender('female')" :disabled="genderFilter === 'female'">Show Female</el-button>
      </div>
  
      <!-- Chart for Gender Count with fixed dimensions -->
      <div style="max-width: 600px; margin: 0 auto;">
        <canvas id="genderChart" style="width: 100%; height: 400px; margin-bottom: 20px;"></canvas>
      </div>
  
      <!-- New Chart for Students Based on Class -->
      <div v-show="classChartInstance" style="max-width: 600px; margin: 0 auto;">
        <canvas id="classChart" style="width: 100%; height: 400px; margin-bottom: 20px;"></canvas>
      </div>
  
      <!-- New Chart for Students Based on Class and Section -->
      <div v-show="classSectionChartInstance" style="max-width: 600px; margin: 0 auto;">
        <canvas id="classSectionChart" style="width: 100%; height: 400px; margin-bottom: 20px;"></canvas>
      </div>
  
      <!-- Print Button using Element Plus -->
      <el-button type="primary" icon="el-icon-printer" @click="printReport">Print Report</el-button>
  <!-- Button for printing all records -->
<el-button type="primary" icon="el-icon-printer" @click="printAllRecords">Print All Records</el-button>

<div>
  <h2>Financial Report</h2>
  <el-button type="primary" @click="fetchFinancialReport">Fetch Financial Report</el-button>
  <el-button type="success" @click="fetchFinancialReportExcel">Export to Excel</el-button>
  <!-- <div>
    <p>Total Students: {{ financialReport.totalStudents }}</p>
    <p>Students with Financial Documents: {{ financialReport.studentsWithDocuments }}</p>
    <p>Students without Financial Documents: {{ financialReport.studentsWithoutDocuments }}</p>
  </div> -->
</div>

      <!-- Table for displaying students -->
      <div>
        <el-table :data="students" style="width: 100%; margin-top: 20px" stripe>
          <el-table-column  prop="name" label="Name" />
          <el-table-column prop="gender" label="Gender" />
          <el-table-column prop="class.name" label="Class" />
          <el-table-column prop="section.name" label="Section" />
          <el-table-column prop="date_of_birth" label="Age" />
        </el-table>
    
        <!-- Pagination Controls -->
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :current-page="currentPage"
          :total="totalStudents"
          @current-change="handlePageChange"
          style="margin-top: 20px; text-align: center;"
        />
      </div>

      
    </div>
  </template>
  
  <script>
  import { ElMessage } from 'element-plus';
  import axios from 'axios';
  import { Chart } from 'chart.js';
  import jsPDF from 'jspdf';
  import * as XLSX from 'xlsx';
import 'jspdf-autotable'; // Import the autoTable plugin
import logoWater from "@/assets/img/logo-school-one.png";
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
    data() {
      return {
        financialReport: {
            totalStudents: 0,
            studentsWithDocuments: 0,
            studentsWithoutDocuments: 0,
            students: [],
        },
        students: [],
        maleCount: 0,
        femaleCount: 0,
        classCount: 0,
        sectionCount: 0,
        className: '',
        genderFilter: '',
        classId: '',
        sectionId: '',
        classes: [],
        sections: [],
        genderChartInstance: null,
        classChartInstance: null,
        classSectionChartInstance: null,
        currentPage: 1,
        totalStudents: 0,
        totalPages: 0,
        perPage: 10, // Number of students to display per page
        filterType: 'gender', // Track the active filter type ('gender' or 'class')
      };
    },
    methods: {
      exportToExcel() {
      // Prepare data for Excel
      const summaryData = [
        ['Total Students', this.financialReport.total_students || 'N/A'],
        ['Students with Financial Documents', this.financialReport.students_with_documents || 'N/A'],
        ['Students without Financial Documents', this.financialReport.students_without_documents || 'N/A']
      ];

      const studentData = this.financialReport.students.map(student => {
        const hasFinancialDocuments = student.parent && student.parent.financial_documents && student.parent.financial_documents.length > 0 ? 'Yes' : 'No';
        const parentName = student.parent ? `${student.parent.first_name} ${student.parent.last_name}` : 'N/A';

        let receiptNumber = 'N/A';
        let totalAmount = 'N/A';
        let finalAmount = 'N/A';
        let valueReceived = 'N/A';
        let remainingAmount = 'N/A';
        let paymentMethod = 'N/A';
        let subscriptionFeesCategory = 'N/A';

        if (hasFinancialDocuments === 'Yes') {
          const financialDoc = student.parent.financial_documents[0];
          receiptNumber = financialDoc.receipt_number || 'N/A';
          totalAmount = financialDoc.total_amount || 'N/A';
          finalAmount = financialDoc.final_amount || 'N/A';
          valueReceived = financialDoc.value_received || 'N/A';
          remainingAmount = financialDoc.remaining_amount || 'N/A';
          paymentMethod = financialDoc.payment_method || 'N/A';
          subscriptionFeesCategory = financialDoc.subscription_fees
            .map(fee => `${fee.category} (${fee.sub_category}: ${fee.amount || 'N/A'})`)
            .join(', ') || 'N/A';
        }

        return [
          student.name || 'N/A',
          student.class ? student.class.name : 'N/A',
          student.section ? student.section.name : 'N/A',
          parentName,
          receiptNumber,
          totalAmount,
          finalAmount,
          remainingAmount,
          valueReceived,
          paymentMethod,
          subscriptionFeesCategory
        ];
      });

      // Create a workbook and add sheets for summary and student data
      const workbook = XLSX.utils.book_new();
      const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
      const studentSheet = XLSX.utils.aoa_to_sheet([
        ['Name', 'Class', 'Section', 'Parent', 'Receipt Number', 'Total Amount', 'Final Amount', 'Remaining Amount', 'Value Received', 'Payment Method', 'Subscription Category'],
        ...studentData
      ]);

      // Append sheets to the workbook
      XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');
      XLSX.utils.book_append_sheet(workbook, studentSheet, 'Student Data');

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, 'financial-report.xlsx');
    },
      async fetchClasses() {
        try {
          const response = await api.get('/classes');
          this.classes = response.data;
        } catch (error) {
          ElMessage.error('Error fetching classes');
          console.error('Error fetching classes:', error);
        }
      },
      async fetchSections(classId) {
        try {
          const response = await api.get(`/report-sections-based-on-class/${classId}`);
          this.sections = response.data.sections;
        } catch (error) {
          ElMessage.error('Error fetching sections');
          console.error('Error fetching sections:', error);
        }
      },

      async fetchFinancialReport() {
        try {
            const response = await api.get('/financial-report', {
                params: {
                    gender: this.genderFilter,
                    class: this.classId,
                    section: this.sectionId,
                },
            });
            this.financialReport = response.data;
            this.generateFinancialPDF(); // Automatically generate PDF after fetching
     
       
        } catch (error) {
            ElMessage.error('Error fetching financial report');
            console.error('Error fetching financial report:', error);
        }
    },
    async fetchFinancialReportExcel() {
        try {
            const response = await api.get('/financial-report', {
                params: {
                    gender: this.genderFilter,
                    class: this.classId,
                    section: this.sectionId,
                },
            });
            this.financialReport = response.data;

            this.exportToExcel();
       
        } catch (error) {
            ElMessage.error('Error fetching financial report');
            console.error('Error fetching financial report:', error);
        }
    },
    generateFinancialPDF() {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(18);
    doc.text('Financial Report', 105, 10, { align: 'center' });

    // Summary Section
    doc.setFontSize(12);
    const summaryData = [
        `Total Students: ${this.financialReport.total_students}`,
        `Students with Financial Documents: ${this.financialReport.students_with_documents}`,
        `Students without Financial Documents: ${this.financialReport.students_without_documents}`
    ];
    summaryData.forEach((line, index) => {
        doc.text(line, 14, 20 + index * 8);
    });

    // Space between summary and table
    doc.setLineWidth(0.5);
    doc.line(14, 45, 196, 45);  // Horizontal line for separation

    // Prepare table data with shortened labels
    const tableData = this.financialReport.students.map(student => {
        const hasFinancialDocuments = student.parent && student.parent.financial_documents && student.parent.financial_documents.length > 0 ? 'Yes' : 'No';
        const parentName = student.parent ? `${student.parent.first_name} ${student.parent.last_name}` : 'N/A';

        let receiptNumber = 'N/A';
        let totalAmount = 'N/A';
        let finalAmount = 'N/A';
        let valueReceived = 'N/A';
        let remainingAmount = 'N/A';
        let paymentMethod = 'N/A';
        let subscriptionFeesCategory = 'N/A';

        if (hasFinancialDocuments === 'Yes') {
            const financialDoc = student.parent.financial_documents[0];
            receiptNumber = financialDoc.receipt_number || 'N/A';
            totalAmount = financialDoc.total_amount || 'N/A';
            finalAmount = financialDoc.final_amount || 'N/A';
            valueReceived = financialDoc.value_received || 'N/A';
            remainingAmount = financialDoc.remaining_amount || 'N/A';
            paymentMethod = financialDoc.payment_method || 'N/A';
            subscriptionFeesCategory = financialDoc.subscription_fees
        .map(fee => `${fee.category} (${fee.sub_category}: ${fee.amount || 'N/A'})`)
        .join(', ') || 'N/A';
        }

        return [
            student.name || 'N/A',
            student.class ? student.class.name : 'N/A',
            student.section ? student.section.name : 'N/A',
    
            parentName,
            receiptNumber,
            totalAmount,
            finalAmount,
            remainingAmount,
            valueReceived,
            paymentMethod,
            subscriptionFeesCategory
        ];
    });

    // Table headers with shorter labels
    const tableHeaders = [
        ['Name', 'Class', 'Sec', 'Parent', 'Rec#', 'Total ($)', 'Final ($)', 'Remain ($)', 'Received ($)', 'Method', 'Sub. Cat']
    ];

    // Auto-table options for better layout
    doc.autoTable({
        head: tableHeaders,
        body: tableData,
        startY: 50, // Start the table below the summary
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        styles: {
            fontSize: 7, // Smaller font size for better fit
            cellPadding: 1, // Less padding for more data visibility
            overflow: 'linebreak'
        },
        columnStyles: {
            0: { cellWidth: 18 },   // Name
            1: { cellWidth: 12 },   // Class
            2: { cellWidth: 10 },   // Section
            3: { cellWidth: 15 },   // Has Document
            4: { cellWidth: 30 },   // Parent Name
            5: { cellWidth: 15 },   // Receipt Number
            6: { cellWidth: 18 },   // Total Amount
            7: { cellWidth: 18 },   // Final Amount
            8: { cellWidth: 18 },   // Remaining Amount
            9: { cellWidth: 18 },   // Value Received
            10: { cellWidth: 18 },  // Payment Method
            11: { cellWidth: 20 }   // Subscription Fees Category
        },
        theme: 'grid',  // Use grid theme for readability
        headStyles: {
            fillColor: [220, 220, 220], // Light gray background for headers
            textColor: [0, 0, 0], // Black text for headers
            fontSize: 9
        },
        bodyStyles: {
            fontSize: 7, // Smaller font for data cells
        },
        pageBreak: 'auto', // Automatically handle page breaks
        showHead: 'everyPage', // Repeat table headers on new pages
    });

    // Save the PDF
    doc.save('financial-report.pdf');
},





      async fetchStudentsByGender() {
    try {
      const response = await api.get('/reports/students', {
        params: {
          gender: this.genderFilter,
          per_page: this.perPage,
          page: this.currentPage, // Pass the current page
        },
      });
      this.students = response.data.students;
      this.maleCount = response.data.male_count;
      this.femaleCount = response.data.female_count;
      this.totalStudents = response.data.total_students;
      this.updateGenderChart();
    } catch (error) {
      ElMessage.error('Error fetching students by gender');
      console.error('Error fetching students by gender:', error);
    }
  },

  async fetchClassStudents() {
    try {
      const response = await api.get('/reports/class-students', {
        params: {
          class_id: this.classId,
          section_id: this.sectionId,
          gender: this.genderFilter,
          per_page: this.perPage,
          page: this.currentPage, // Pass the current page
        },
      });
      this.students = response.data.students;
      this.classCount = response.data.class_count;
      this.sectionCount = response.data.section_count;
      this.className = response.data.class_name;
      this.totalStudents = response.data.total_students; // Update total students count
      this.totalPages = response.data.total_pages; // Set total pages
      this.updateClassChart();
      this.updateClassSectionChart();
    } catch (error) {
      ElMessage.error('Error fetching class students');
      console.error('Error fetching class students:', error);
    }
  },
  onClassChange() {
    this.sectionId = ''; // Reset section ID when class changes
    this.fetchSections(this.classId); // Fetch sections for the selected class
    this.filterType = 'class'; // Set the active filter type
    this.fetchClassStudents(); // Fetch students for the selected class
  },
      updateGenderChart() {
        this.$nextTick(() => {
          const canvas = document.getElementById('genderChart');
          if (!canvas) {
            console.error('Canvas element not found');
            return;
          }
  
          const ctx = canvas.getContext('2d');
  
          if (this.genderChartInstance) {
            this.genderChartInstance.destroy();
          }
  
          let labels = [];
          let data = [];
  
          if (this.genderFilter === 'male') {
            labels = ['Male'];
            data = [this.maleCount];
          } else if (this.genderFilter === 'female') {
            labels = ['Female'];
            data = [this.femaleCount];
          } else {
            labels = ['Male', 'Female'];
            data = [this.maleCount, this.femaleCount];
          }
  
          this.genderChartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: 'Gender Count',
                  data: data,
                  backgroundColor: ['#3498db', '#e74c3c'],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        });
      },
      updateClassChart() {
        this.$nextTick(() => {
          const canvas = document.getElementById('classChart');
          if (!canvas) {
            console.error('Canvas element not found for class chart');
            return;
          }
  
          const ctx = canvas.getContext('2d');
  
          if (this.classChartInstance) {
            this.classChartInstance.destroy();
          }
  
          const labels = [this.className || 'Unknown Class'];
          const data = [this.classCount];
  
          this.classChartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: 'Students in Selected Class',
                  data: data,
                  backgroundColor: ['#2ecc71'],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        });
      },
      updateClassSectionChart() {
        this.$nextTick(() => {
          const canvas = document.getElementById('classSectionChart');
          if (!canvas) {
            console.error('Canvas element not found for class-section chart');
            return;
          }
  
          const ctx = canvas.getContext('2d');
  
          if (this.classSectionChartInstance) {
            this.classSectionChartInstance.destroy();
          }
  
          const labels = [`Class: ${this.className || 'Unknown'} Section: ${this.sectionId || 'Unknown'}`];
          const data = [this.sectionCount];
  
          this.classSectionChartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: 'Students in Selected Class & Section',
                  data: data,
                  backgroundColor: ['#9b59b6'],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        });
      },
      handlePageChange(page) {
    this.currentPage = page;
    if (this.filterType === 'gender') {
      this.fetchStudentsByGender(); // Fetch based on gender if active filter is gender
    } else if (this.filterType === 'class') {
      this.fetchClassStudents(); // Fetch based on class if active filter is class
    }
  },
// Print report functionality
printReport() {
    const doc = new jsPDF();
    doc.text('Student Report', 10, 10);

    const tableData = this.students.map(student => [
      student.name, 
      student.gender, 
      student.class ? student.class.name : 'N/A', 
      student.section ? student.section.name : 'N/A', 
      student.date_of_birth
    ]);

    doc.autoTable({
      head: [['Name', 'Gender', 'Class', 'Section', 'Age']],
      body: tableData,
    });

    doc.save('student-report.pdf');
  },


  async printAllRecords() {
  try {
    const response = await api.get('/get-all-students-for-print', {
      params: {
        gender: this.genderFilter,
        class: this.classId,
        section: this.sectionId,
      },
    });

    // Log the response data for debugging
    console.log(response.data);

    // Assuming response data is in the expected format
    const studentsData = response.data;

    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add watermark logo
    const logoWidth = 50; // Adjust as necessary
    const logoHeight = 50; // Adjust as necessary


    // Set up the header with a title and background color
    const headerTitle = "CALEDONIAN INTERNATIONAL SCHOOL";
    const headerBackgroundColor = '#00416A'; // Change this color as needed
    const headerTextColor = '#E4E5E6'; // Change this color for the text

    // Draw header background
    doc.setFillColor(headerBackgroundColor);
    doc.rect(0, 0, doc.internal.pageSize.width, 30, 'F'); // Rectangle for header background

    // Set font styles for header
    doc.setTextColor(headerTextColor);
    doc.setFontSize(20);
    doc.text(headerTitle, 15, 20); // Positioning the title

    // Prepare the data for the PDF
    const tableData = studentsData.map(student => [
      student.id || 'N/A',
      student.name || 'N/A',
      student.gender || 'N/A',
      student.class ? student.class.name : 'N/A',
      student.section?.name || 'N/A',
      student.date_of_birth || 'N/A',
    ]);

    // Move below the header
    doc.autoTable({
      head: [['File Number', 'Name', 'Gender', 'Class', 'Section', 'Date of Birth']],
      body: tableData,
      startY: 35, // Start drawing the table below the header
    });

    // Save the PDF
    doc.save('students_report.pdf');
  } catch (error) {
    ElMessage.error('Error fetching student records for printing');
    console.error('Error fetching student records:', error);
  }
},


    },
    mounted() {
        this.fetchStudentsByGender();
      this.fetchClasses(); // Fetch the available classes when the component is mounted
    },
  };
  </script>
  
  <style scoped>
  /* Additional styles can be placed here */
  </style>
