<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div :class="{'dark-mode': isDarkMode}" class="card my-4">
            <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
              <div class="d-flex justify-content-around">
                <h6 class="text-white text-capitalize ps-3">Manage Activity Logs</h6>
                <el-input
                  style="width: 200px;"
                  v-model="search"
                  placeholder="Search activity logs..."
                  class="search-input"
                  @input="fetchLogs"
                />
              </div>
            </div>
            <div>
              <el-table :data="activityLogs.data" style="width: 100%">
                <el-table-column prop="user.username" label="Username" width="150" />
                <el-table-column prop="action" label="Action" width="150" />
  
                <!-- Old Data Column with Read More and Null Check -->
                <el-table-column label="Old Data" width="250">
                  <template #default="scope">
                    <div>
                      <span>{{ truncateText(scope.row.old_data || 'No data available') }}</span>
                      <el-button
                        v-if="scope.row.old_data && scope.row.old_data.length > textLimit"
                        type="text"
                        @click="toggleReadMore(scope.row.old_data)"
                      >
                        {{ expandedTexts[scope.row.old_data] ? 'Read less' : 'Read more' }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
  
                <!-- New Data Column with Read More and Null Check -->
                <el-table-column label="New Data" width="250">
                  <template #default="scope">
                    <div>
                      <span>{{ truncateText(scope.row.new_data || 'No data available') }}</span>
                      <el-button
                        v-if="scope.row.new_data && scope.row.new_data.length > textLimit"
                        type="text"
                        @click="toggleReadMore(scope.row.new_data)"
                      >
                        {{ expandedTexts[scope.row.new_data] ? 'Read less' : 'Read more' }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
  
                <el-table-column prop="description" label="Description" width="250" />
                <el-table-column prop="created_at" label="Date" width="180" />
              </el-table>
  
              <div class="d-flex justify-content-center mt-4">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="perPage"
                  :total="activityLogs.total"
                  layout="total, prev, pager, next"
                  background
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
  export default {
    setup() {
      const activityLogs = ref({ data: [], total: 0 });
      const search = ref('');
      const currentPage = ref(1);
      const perPage = ref(10);
      const isDarkMode = ref(false);
      const textLimit = ref(50); // Limit for truncating text
      const expandedTexts = ref({}); // To track expanded text
  
      const fetchLogs = async () => {
        try {
          const response = await api.get('/activity-logs', {
            params: {
              search: search.value,
              page: currentPage.value,
              perPage: perPage.value,
            },
          });
          activityLogs.value = response.data;
        } catch (error) {
          console.error('Error fetching activity logs:', error);
        }
      };
  
      const handleCurrentChange = (page) => {
        currentPage.value = page;
        fetchLogs();
      };
  
      const truncateText = (text) => {
        if (!text) return '';
        const expanded = expandedTexts.value[text];
        if (expanded || text.length <= textLimit.value) {
          return text;
        }
        return text.substring(0, textLimit.value) + '...';
      };
  
      const toggleReadMore = (text) => {
        expandedTexts.value = {
          ...expandedTexts.value,
          [text]: !expandedTexts.value[text],
        };
      };
  
      onMounted(fetchLogs);
  
      return {
        activityLogs,
        search,
        currentPage,
        perPage,
        isDarkMode,
        textLimit,
        expandedTexts,
        fetchLogs,
        handleCurrentChange,
        truncateText,
        toggleReadMore,
      };
    },
  };
  </script>
  
  <style scoped>
  .search-input {
    margin-left: auto;
  }
  .bg-header-table {
    background: #3c3c3c;
  }
  .dark-mode {
    background-color: #1f1f1f;
    color: white;
  }
  </style>
  